import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    TextField,
    Typography,
    IconButton,
    Collapse,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import { ExpandMore, ExpandLess, ViewModule, ViewList } from '@mui/icons-material';
import MemoCard from './components/MemoCard';
import MemoList from './components/MemoList';
import { SuccessSnackbar, ErrorSnackbar, NetworkErrorSnackbar } from './components/common/Snackbar';
import ColorFilterButton from './components/ColorFilterButton'; // カラーフィルターボタンのインポート

function MemoPage({ language, saveToGoogleDrive, loadFromGoogleDrive }) {
    const [memos, setMemos] = useState([]);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [formExpanded, setFormExpanded] = useState(false); // 初期値をfalseに変更
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedMemo, setSelectedMemo] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editTitle, setEditTitle] = useState('');
    const [editContent, setEditContent] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [viewMode, setViewMode] = useState('card'); // 追加
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false); //通信エラー時のスナックバー表示に使う
    const [networkErrorSnackbarOpen, setNetworkErrorSnackbarOpen] = useState(false); // ネットワークエラー時のスナックバー表示に使う
    const [selectedColors, setSelectedColors] = useState(['#ffffff', '#f8d7da', '#d1ecf1', '#fffacd']); // 初期状態で全色選択

    const titleMaxLength = 30;
    const contentMaxLength = 300;

    const colors = ['#ffffff', '#f8d7da', '#d1ecf1', '#fffacd'];

    const loadMemos = useCallback(async () => {
        try {
            const savedMemos = await loadFromGoogleDrive('memos.json');
            if (savedMemos) {
                setMemos(savedMemos);
                if (savedMemos.length === 0) {
                    setFormExpanded(true); // メモが0枚の時にフォームを開く
                }
            }else{
                setFormExpanded(true); // ログイン前、savedMemosがundefinedの場合にフォームを開く
            }
        } catch (error) {
            console.error('Error loading memos:', error);
            setNetworkErrorSnackbarOpen(true); // ネットワークエラー時にSnackbarを表示
        }
        setLoading(false);
    }, [loadFromGoogleDrive]);

    useEffect(() => {
        loadMemos();
    }, [loadMemos]);

    const saveMemos = async (updatedMemos) => {
        setMemos(updatedMemos);
        // フィルタリングされていない全てのメモを取得して保存
        const allMemos = [...updatedMemos, ...memos.filter(memo => !selectedColors.includes(memo.color))];
        return await saveToGoogleDrive(allMemos, 'memos.json');
    };

    // フィルターされたメモリストを生成する際に元のインデックスを保持
    const filteredMemos = memos
        .map((memo, index) => ({ ...memo, originalIndex: index }))
        .filter(memo => selectedColors.includes(memo.color));

    const addMemo = async () => {
        if (getByteLength(title) > titleMaxLength * 2 || getByteLength(content) > contentMaxLength * 2) {
            alert('タイトルまたは内容が制限を超えています。');
            return;
        }
        const newMemo = { title, content, locked: false, color: colors[0], tasklist: false, status: '未着手' };
        const updatedMemos = [...memos, newMemo];
        const success = await saveMemos(updatedMemos);
        if (success) {
            setTitle('');
            setContent('');
            showSnackbar();
        } else {
            showErrorSnackbar();
        }
    };

    const getByteLength = (str) => {
        let byteLength = 0;
        for (let i = 0; i < str.length; i++) {
            byteLength += str.charCodeAt(i) > 0x7F ? 2 : 1;
        }
        return byteLength;
    };

    const clearForm = () => {
        setTitle('');
        setContent('');
    };

    const clearMemos = () => {
        const confirmClear = window.confirm(language === 'ja' ? 'メモを全削除しますか？この操作は元に戻せません。' : 'Are you sure you want to delete all memos? This action cannot be undone.');
        if (confirmClear) {
            const updatedMemos = memos.filter(memo => memo.locked);
            saveMemos(updatedMemos);
        }
    };    

    const toggleComplete = (index) => {
        const originalIndex = filteredMemos[index].originalIndex;
        const updatedMemos = memos.map((memo, i) => {
            if (i === originalIndex) {
                const status = memo.status === '完了' ? '未着手' : '完了';
                return { ...memo, status };
            }
            return memo;
        });
        const updatedTaskMemos = updatedMemos.map((memo) =>
            memo.tasklist ? { ...memo, completed: memo.completed } : memo
        );
        saveMemos(updatedTaskMemos);
    };  

    const toggleLock = (index) => {
        const originalIndex = filteredMemos[index].originalIndex;
        const updatedMemos = memos.map((memo, i) =>
            i === originalIndex ? { ...memo, locked: !memo.locked } : memo
        );
        saveMemos(updatedMemos);
    };
    
    const deleteMemo = (index) => {
        const originalIndex = filteredMemos[index].originalIndex;
        const confirmDelete = window.confirm(language === 'ja' ? '削除すると元に戻せません。本当に削除しますか？' : 'Are you sure you want to delete this memo? This action cannot be undone.');
        if (confirmDelete) {
            const updatedMemos = memos.filter((_, i) => i !== originalIndex);
            saveMemos(updatedMemos);
        }
    };

    const copyMemo = (index) => {
        const memo = memos[index];
        setTitle(memo.title);
        setContent(memo.content);
        setFormExpanded(true);
    };

    const toggleFormExpand = () => {
        setFormExpanded(!formExpanded);
    };

    const openDialog = (memo) => {
        setSelectedMemo(memo);
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogOpen(false);
        setSelectedMemo(null);
    };

    const openEditDialog = (index) => {
        const memo = memos[index];
        setEditTitle(memo.title);
        setEditContent(memo.content);
        setEditIndex(index);
        setEditDialogOpen(true);
    };

    const closeEditDialog = () => {
        setEditDialogOpen(false);
        setEditTitle('');
        setEditContent('');
        setEditIndex(null);
    };

    const updateMemo = () => {
        const updatedMemos = memos.map((memo, i) =>
            i === editIndex ? { ...memo, title: editTitle, content: editContent } : memo
        );
        saveMemos(updatedMemos);
        closeEditDialog();
        showSnackbar();
    };

    const changeColor = (index, color) => {
        const updatedMemos = memos.map((memo, i) =>
            i === index ? { ...memo, color: color } : memo
        );
        saveMemos(updatedMemos);
    };

    const toggleTasklist = (index) => {
        const updatedMemos = memos.map((memo, i) =>
            i === index ? { ...memo, tasklist: !memo.tasklist } : memo
        );
        saveMemos(updatedMemos);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
        setErrorSnackbarOpen(false); // エラーSnackbarも閉じる
        setNetworkErrorSnackbarOpen(false); // ネットワークエラーSnackbarも閉じる
    };

    const showSnackbar = () => {
        setSnackbarOpen(true);
    };

    const showErrorSnackbar = () => {
        setErrorSnackbarOpen(true);
    };

    const handleViewChange = (event, nextView) => {
        setViewMode(nextView);
    };

    const handleColorFilterChange = (selectedColors) => {
        setSelectedColors(selectedColors);
    };

    return (
        <Container maxWidth="lg" sx={{ textAlign: 'center', padding: '20px' }}>
            {/* フォームバー */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#ffe6e6',
                    padding: '8px 16px',
                    borderRadius: '4px 4px 0 0',
                    cursor: 'pointer',
                    border: '1px solid #ccc',
                }}
                onClick={toggleFormExpand}
            >
                <Typography variant="subtitle1">{language === 'ja' ? 'メモを追加' : 'Add Memo'}</Typography>
                <IconButton size="small">{formExpanded ? <ExpandLess /> : <ExpandMore />}</IconButton>
            </Box>
            {/* フォーム部 */}
            <Collapse in={formExpanded}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '100%' },
                        mb: 3,
                        border: '1px solid #ccc',
                        borderTop: 'none',
                        borderRadius: '0 0 4px 4px',
                        padding: '16px',
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        label={language === 'ja' ? `タイトル (最大${titleMaxLength}文字)` : `Title (max ${titleMaxLength} characters)`}
                        variant="outlined"
                        value={title}
                        helperText={`${getByteLength(title) / 2} / ${titleMaxLength}`}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            if (getByteLength(inputValue) <= titleMaxLength * 2) {
                                setTitle(inputValue);
                            }
                        }}
                    />
                    <TextField
                        label={language === 'ja' ? `内容 (最大${contentMaxLength}文字)` : `Content (max ${contentMaxLength} characters)`}
                        variant="outlined"
                        multiline
                        rows={6}
                        value={content}
                        helperText={`${getByteLength(content) / 2} / ${contentMaxLength}`}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            if (getByteLength(inputValue) <= contentMaxLength * 2) {
                                setContent(inputValue);
                            }
                        }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button variant="contained" color="primary" onClick={addMemo} sx={{ ml: 2 }}>
                            {language === 'ja' ? 'メモを追加' : 'Add Memo'}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={clearForm} sx={{ ml: 2 }}>
                            {language === 'ja' ? 'フォームをクリア' : 'Clear Form'}
                        </Button>
                        <Button variant="contained" color="error" onClick={clearMemos} sx={{ ml: 2 }}>
                            {language === 'ja' ? 'メモを全削除' : 'Clear All Memos'}
                        </Button>
                    </Box>
                </Box>
            </Collapse>
            {!formExpanded && <Box sx={{ mb: 2 }} />}

            {/* フィルターボタン */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <ColorFilterButton colors={colors} selectedColors={selectedColors} onChange={handleColorFilterChange} language={language} />
            </Box>

            {/* コンテンツ部 */}
            <Container maxWidth="lg" sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '8px' }}>
                <ToggleButtonGroup
                    value={viewMode}
                    exclusive
                    onChange={handleViewChange}
                    aria-label="view mode"
                    sx={{ mb: 2 }}
                >
                    <ToggleButton value="card" aria-label="card view">
                        <ViewModule />
                    </ToggleButton>
                    <ToggleButton value="list" aria-label="list view">
                        <ViewList />
                    </ToggleButton>
                </ToggleButtonGroup>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {viewMode === 'card' ? (
                            <Grid container spacing={3}>
                                {memos
                                    .filter(memo => selectedColors.includes(memo.color))
                                    .map((memo, index) => (
                                        <MemoCard
                                            key={index}
                                            memo={memo}
                                            index={index}
                                            colors={colors}
                                            changeColor={changeColor}
                                            toggleTasklist={toggleTasklist}
                                            openEditDialog={openEditDialog}
                                            toggleComplete={toggleComplete}
                                            copyMemo={copyMemo}
                                            deleteMemo={deleteMemo}
                                            toggleLock={toggleLock}
                                            openDialog={openDialog}
                                            language={language}
                                        />
                                    ))}
                            </Grid>
                        ) : (
                            <MemoList
                                memos={memos.filter(memo => selectedColors.includes(memo.color))}
                                colors={colors}
                                changeColor={changeColor}
                                toggleTasklist={toggleTasklist}
                                openEditDialog={openEditDialog}
                                toggleComplete={toggleComplete}
                                copyMemo={copyMemo}
                                deleteMemo={deleteMemo}
                                toggleLock={toggleLock}
                                openDialog={openDialog}
                                language={language}
                            />
                        )}
                    </>
                )}
            </Container>
            <Dialog open={dialogOpen} onClose={closeDialog} fullWidth maxWidth="sm">
                <DialogTitle>{selectedMemo?.title}</DialogTitle>
                <DialogContent>
                    <Typography sx={{ whiteSpace: 'pre-line' }}>{selectedMemo?.content}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        {language === 'ja' ? '閉じる' : 'Close'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={editDialogOpen} onClose={closeEditDialog} fullWidth maxWidth="sm">
                <DialogTitle>{language === 'ja' ? 'メモを編集' : 'Edit Memo'}</DialogTitle>
                <DialogContent>
                    <TextField
                        label={language === 'ja' ? 'タイトル' : 'Title'}
                        variant="outlined"
                        fullWidth
                        value={editTitle}
                        onChange={(e) => setEditTitle(e.target.value)}
                        inputProps={{ maxLength: titleMaxLength }}
                        helperText={`${editTitle.length} / ${titleMaxLength}`}
                        margin="dense"
                    />
                    <TextField
                        label={language === 'ja' ? '内容' : 'Content'}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={6}
                        value={editContent}
                        onChange={(e) => setEditContent(e.target.value)}
                        inputProps={{ maxLength: contentMaxLength }}
                        helperText={`${editContent.length} / ${contentMaxLength}`}
                        margin="dense"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeEditDialog} color="secondary">
                        {language === 'ja' ? 'キャンセル' : 'Cancel'}
                    </Button>
                    <Button onClick={updateMemo} color="primary">
                        {language === 'ja' ? '保存' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
            <SuccessSnackbar
                open={snackbarOpen}
                onClose={handleSnackbarClose}
                message={language === 'ja' ? '保存されました' : 'Saved successfully'}
            />
            <ErrorSnackbar
                open={errorSnackbarOpen}
                onClose={handleSnackbarClose}
                message={language === 'ja' ? '保存に失敗しました' : 'Save failed'}
            />
            <NetworkErrorSnackbar
                open={networkErrorSnackbarOpen}
                onClose={handleSnackbarClose}
                message={language === 'ja' ? 'ネットワークエラーが発生しました' : 'Network error occurred'}
            />

            <Box sx={{ mt: 4, textAlign: 'center' }}>
                <Typography variant="body2">
                    <a href="/privacy-policy" target="_blank">
                        {language === 'ja' ? 'プライバシーポリシー' : 'Privacy Policy'}
                    </a>
                    {' | '}
                    <a href="/landing">
                        {language === 'ja' ? 'アプリについて' : 'About the App'}
                    </a>
                </Typography>
            </Box>

        </Container>
    );
}

export default MemoPage;
