import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Container,
    Grid,
    Card,
    CardContent,
    Typography,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    CircularProgress,
} from '@mui/material';
import { ArrowForward, ArrowBack, Edit, Delete } from '@mui/icons-material';

const KanbanPage = ({ language, saveToGoogleDrive, loadFromGoogleDrive }) => {
    const [memos, setMemos] = useState([]); // カンバンにtasklist:trueのみ表示する用メモを保持する状態
    const [allMemos, setAllMemos] = useState([]); // すべてのメモを保持する状態
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedMemo, setSelectedMemo] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editTitle, setEditTitle] = useState('');
    const [editContent, setEditContent] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [loading, setLoading] = useState(true);

    const loadMemos = useCallback(async () => {
        const savedMemos = await loadFromGoogleDrive('memos.json');
        if (savedMemos) {
            const taskMemos = savedMemos.filter(memo => memo.tasklist);
            setMemos(taskMemos);
            setAllMemos(savedMemos); // すべてのメモを保持
        }
        setLoading(false);
    }, [loadFromGoogleDrive]);

    useEffect(() => {
        loadMemos();
    }, [loadMemos]);

    const saveMemos = (updatedMemos) => {
        setAllMemos(updatedMemos); // すべてのメモを更新
        saveToGoogleDrive(updatedMemos, 'memos.json'); 
    };
    
    const updateMemoStatus = (memo, newStatus) => {
        const updatedAllMemos = allMemos.map((m) => 
            m === memo ? { ...memo, status: newStatus } : m
        );
    
        const updatedMemos = updatedAllMemos.filter(memo => memo.tasklist);
    
        setAllMemos(updatedAllMemos); // すべてのメモを更新
        setMemos(updatedMemos); // 表示用のメモを更新
        saveToGoogleDrive(updatedAllMemos, 'memos.json');
    };
    

    const deleteMemo = (index) => {
        const updatedMemos = memos.filter((_, i) => i !== index);
        saveMemos(updatedMemos);
    };

    const openDialog = (memo) => {
        setSelectedMemo(memo);
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogOpen(false);
        setSelectedMemo(null);
    };

    const openEditDialog = (index) => {
        const memo = memos[index];
        setEditTitle(memo.title);
        setEditContent(memo.content);
        setEditIndex(index);
        setEditDialogOpen(true);
    };

    const closeEditDialog = () => {
        setEditDialogOpen(false);
        setEditTitle('');
        setEditContent('');
        setEditIndex(null);
    };

    const updateMemo = () => {
        const updatedMemos = memos.map((memo, i) =>
            i === editIndex ? { ...memo, title: editTitle, content: editContent } : memo
        );
        saveMemos(updatedMemos);
        closeEditDialog();
    };

    const getColumnMemos = (status) => {
        return memos.filter(memo => memo.status === status);
    };

    return (
        <Container maxWidth="lg" sx={{ textAlign: 'center', padding: '20px' }}>
            <Typography variant="h4" gutterBottom sx={{ fontFamily: language === 'ja' ? 'Dela Gothic One' : 'Shadows Into Light' }}>
                {language === 'ja' ? 'カンバン' : 'KANBAN'}
            </Typography>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Grid container spacing={2}>
                    {['未着手', '作業中', '完了'].map((status, columnIndex) => (
                        <Grid item xs={12} sm={4} key={status}>
                            <Typography variant="h6" gutterBottom sx={{ fontFamily: language === 'ja' ? 'Dela Gothic One' : 'Shadows Into Light' }}>
                                {language === 'ja' ? status : status === '未着手' ? 'To Do' : status === '作業中' ? 'In Progress' : 'Done'}
                            </Typography>
                            <Box sx={{ minHeight: '300px', backgroundColor: '#f4f4f4', padding: '10px', borderRadius: '4px' }}>
                                {getColumnMemos(status).map((memo, index) => (
                                    <Card
                                        key={index}
                                        sx={{
                                            height: '180px',
                                            backgroundColor: memo.status === '完了' ? '#d3d3d3' : memo.color || 'white',
                                            marginBottom: '10px',
                                            position: 'relative',
                                            cursor: 'pointer',
                                            overflow: 'hidden',
                                            textDecoration: memo.status === '完了' ? 'line-through' : 'none',
                                        }}
                                        onClick={() => openDialog(memo)}
                                    >
                                        <CardContent sx={{ height: '100%' }}>
                                            <Typography
                                                variant="h6"
                                                component="div"
                                                sx={{
                                                    textDecoration: memo.status === '完了' ? 'line-through' : 'none',
                                                    marginTop: '2rem',
                                                }}
                                            >
                                                {memo.title}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                sx={{
                                                    textDecoration: memo.status === '完了' ? 'line-through' : 'none',
                                                    whiteSpace: 'pre-line',
                                                    overflowY: 'auto',
                                                    maxHeight: '80px',
                                                    textAlign: 'left',
                                                    '&::-webkit-scrollbar': {
                                                        width: '0.4em',
                                                        height: '0.4em',
                                                    },
                                                    '&::-webkit-scrollbar-thumb': {
                                                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                                    },
                                                    '&::-webkit-scrollbar-track': {
                                                        backgroundColor: 'transparent',
                                                    },
                                                }}
                                            >
                                                {memo.content}
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ position: 'absolute', bottom: 8, right: 8, display: 'flex', gap: 1 }}>
                                            {columnIndex > 0 && (
                                                <Tooltip title={language === 'ja' ? '前の列に移動' : 'Move to previous column'}>
                                                    <IconButton onClick={(e) => {
                                                        e.stopPropagation();
                                                        updateMemoStatus(memo, ['未着手', '作業中', '完了'][columnIndex - 1]);
                                                    }} sx={{ color: 'green' }}>
                                                        <ArrowBack />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {columnIndex < 2 && (
                                                <Tooltip title={language === 'ja' ? '次の列に移動' : 'Move to next column'}>
                                                    <IconButton onClick={(e) => {
                                                        e.stopPropagation();
                                                        updateMemoStatus(memo, ['未着手', '作業中', '完了'][columnIndex + 1]);
                                                    }} sx={{ color: 'green' }}>
                                                        <ArrowForward />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            <Tooltip title={language === 'ja' ? '編集' : 'Edit'}>
                                                <IconButton onClick={(e) => {
                                                    e.stopPropagation();
                                                    openEditDialog(index);
                                                }} color="primary">
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={language === 'ja' ? '削除' : 'Delete'}>
                                                <span>
                                                    <IconButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            deleteMemo(index);
                                                        }}
                                                        color={memo.locked ? 'default' : 'error'}
                                                        disabled={memo.locked}
                                                        sx={{
                                                            color: memo.locked ? 'gray' : 'error.main',
                                                        }}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Box>
                                    </Card>
                                ))}
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            )}
            <Dialog open={dialogOpen} onClose={closeDialog} fullWidth maxWidth="sm">
                <DialogTitle>{selectedMemo?.title}</DialogTitle>
                <DialogContent>
                    <Typography sx={{ whiteSpace: 'pre-line' }}>{selectedMemo?.content}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        {language === 'ja' ? '閉じる' : 'Close'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={editDialogOpen} onClose={closeEditDialog} fullWidth maxWidth="sm">
                <DialogTitle>{language === 'ja' ? 'メモを編集' : 'Edit Memo'}</DialogTitle>
                <DialogContent>
                    <TextField
                        label={language === 'ja' ? 'タイトル' : 'Title'}
                        variant="outlined"
                        fullWidth
                        value={editTitle}
                        onChange={(e) => setEditTitle(e.target.value)}
                        margin="dense"
                    />
                    <TextField
                        label={language === 'ja' ? '内容' : 'Content'}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={6}
                        value={editContent}
                        onChange={(e) => setEditContent(e.target.value)}
                        margin="dense"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeEditDialog} color="secondary">
                        {language === 'ja' ? 'キャンセル' : 'Cancel'}
                    </Button>
                    <Button onClick={updateMemo} color="primary">
                        {language === 'ja' ? '保存' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default KanbanPage;
