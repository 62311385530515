// ./components/MemoCard.js

import React from 'react';
import { Grid, Card, CardContent, Typography, IconButton, Box, Tooltip } from '@mui/material';
import { Check, Delete, FileCopy, Edit, CheckBox, CheckBoxOutlineBlank, Lock, LockOpen } from '@mui/icons-material';

const MemoCard = ({ memo, index, colors, changeColor, toggleTasklist, openEditDialog, toggleComplete, copyMemo, deleteMemo, toggleLock, openDialog, language }) => {
    return (
        <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
                sx={{
                    height: '250px',
                    backgroundColor: memo.status === '完了' ? '#d3d3d3' : memo.color || 'white',
                    position: 'relative',
                    cursor: 'pointer',
                    overflow: 'hidden',
                }}
                onClick={() => openDialog(memo)}
            >
                <CardContent sx={{ height: '100%' }}>
                    <Box sx={{ position: 'absolute', bottom: 8, right: 8, display: 'flex', gap: 1 }}>
                        {colors.map((color) => (
                            <IconButton
                                key={color}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    changeColor(index, color);
                                }}
                                sx={{
                                    backgroundColor: color,
                                    width: 10,
                                    height: 10,
                                    border: '1px solid black',
                                }}
                            />
                        ))}
                    </Box>
                    <Box sx={{ position: 'absolute', top: 8, right: 8, display: 'flex', gap: 1 }}>
                        <Tooltip title={language === 'ja' ? 'タスクに追加' : 'Add to tasklist'}>
                            <IconButton
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleTasklist(index);
                                }}
                                color={memo.tasklist ? 'primary' : 'default'}
                            >
                                {memo.tasklist ? <CheckBox fontSize="small" /> : <CheckBoxOutlineBlank fontSize="small" />}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={language === 'ja' ? '編集' : 'Edit'}>
                            <IconButton
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openEditDialog(index);
                                }}
                                color="primary"
                            >
                                <Edit fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={language === 'ja' ? '完了/未完了' : 'Complete/Incomplete'}>
                            <IconButton
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleComplete(index);
                                }}
                                color={memo.completed ? 'default' : 'primary'}
                            >
                                <Check fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={language === 'ja' ? 'コピー' : 'Copy'}>
                            <IconButton
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    copyMemo(index);
                                }}
                                color="primary"
                            >
                                <FileCopy fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={language === 'ja' ? '削除' : 'Delete'}>
                            <span>
                                <IconButton
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        deleteMemo(index);
                                    }}
                                    color="error"
                                    disabled={memo.locked}
                                >
                                    <Delete fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={memo.locked ? (language === 'ja' ? 'ロック解除' : 'Unlock') : (language === 'ja' ? 'ロック' : 'Lock')}>
                            <IconButton
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleLock(index);
                                }}
                                color="default"
                            >
                                {memo.locked ? <Lock fontSize="small" /> : <LockOpen fontSize="small" />}
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{
                            textDecoration: memo.status === '完了' ? 'line-through' : 'none',
                            marginTop: '2rem',
                        }}
                    >
                        {memo.title}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                            textDecoration: memo.status === '完了' ? 'line-through' : 'none',
                            whiteSpace: 'pre-line',
                            marginTop: 1,
                            textAlign: 'left',
                            overflowY: 'auto',
                            maxHeight: '150px',
                            '&::-webkit-scrollbar': {
                                width: '0.4em',
                                height: '0.4em',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        {memo.content}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default MemoCard;
