//useGoogleApi.js
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import { useState, useEffect } from 'react';
import axios from 'axios';

const useGoogleApi = () => {
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [accessToken, setAccessToken] = useState(null);

    const login = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            setAccessToken(tokenResponse.access_token);
            setIsSignedIn(true);
            localStorage.setItem('google_token', tokenResponse.access_token);
            localStorage.setItem('google_token_expiry', (Date.now() / 1000) + tokenResponse.expires_in);
        },
        onError: (error) => {
            console.error('Login Failed:', error);
        },
        scope: 'https://www.googleapis.com/auth/drive.file',
        redirectUri: window.location.origin
    });

    const signOut = () => {
        googleLogout();
        setAccessToken(null);
        setIsSignedIn(false);
        localStorage.removeItem('google_token');
        localStorage.removeItem('google_token_expiry');
    };

    const uploadFile = (fileContent, fileName) => {
        const boundary = '-------314159265358979323846';
        const delimiter = "\r\n--" + boundary + "\r\n";
        const close_delim = "\r\n--" + boundary + "--";

        const metadata = {
            'name': fileName,
            'mimeType': 'application/json'
        };

        const multipartRequestBody =
            delimiter + 'Content-Type: application/json\r\n\r\n' +
            JSON.stringify(metadata) +
            delimiter + 'Content-Type: application/json\r\n\r\n' +
            fileContent +
            close_delim;

        axios.post('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', multipartRequestBody, {
            headers: {
                'Content-Type': 'multipart/related; boundary="' + boundary + '"',
                'Authorization': 'Bearer ' + accessToken
            }
        }).then((response) => {
            // console.log("File uploaded:", response);
        }).catch((error) => {
            // console.error("Error uploading file:", error);
        });
    };

    const getFileId = async (filename) => {
        const savedToken = localStorage.getItem('google_token');
        if (savedToken) {
            try {
                const response = await axios.get('https://www.googleapis.com/drive/v3/files', {
                    headers: {
                        'Authorization': 'Bearer ' + savedToken,
                    },
                    params: {
                        q: `name='${filename}' and trashed=false`,
                        fields: 'files(id, name)',
                    }
                });

                const files = response.data.files;
                if (files.length > 0) {
                    return files[0].id;
                } else {
                    return null;
                }
            } catch (error) {
                console.error("Error getting file ID from Google Drive:", error);
                return null;
            }
        }
    };

    const saveToGoogleDrive = async (data, filename) => {
        if (!isSignedIn) {
            await login();
        }

        const fileContent = JSON.stringify(data);
        const file = new Blob([fileContent], { type: 'application/json' });
        const metadata = {
            name: filename,
            mimeType: 'application/json',
        };

        const savedToken = localStorage.getItem('google_token');
        if (savedToken) {
            const form = new FormData();
            form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
            form.append('file', file);

            try {
                const fileId = await getFileId(filename);
                let url = 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id';
                let method = 'POST';

                if (fileId) {
                    url = `https://www.googleapis.com/upload/drive/v3/files/${fileId}?uploadType=multipart&fields=id`;
                    method = 'PATCH';
                }

                await axios({
                    url: url,
                    method: method,
                    headers: {
                        'Authorization': 'Bearer ' + savedToken,
                        'Content-Type': 'multipart/form-data'
                    },
                    data: form
                });

                // axiosのresponseを見たい場合は以下のように記述し、resultのコメントアウト、console.log(result)のコメントアウトをはずす
                // const response = await axios({

                // const result = response.data;
                // // console.log(result);

                return true; // 成功
            } catch (error) {
                // console.error("Error saving file to Google Drive:", error);
                return false; // 失敗
            }
        }
    };

    useEffect(() => {
        const savedToken = localStorage.getItem('google_token');
        const tokenExpiry = localStorage.getItem('google_token_expiry');

        if (savedToken && tokenExpiry) {
            const currentTime = Date.now() / 1000;
            if (currentTime < tokenExpiry) {
                setIsSignedIn(true);
                setAccessToken(savedToken);
            } else {
                localStorage.removeItem('google_token');
                localStorage.removeItem('google_token_expiry');
            }
        }
    }, []);


    const loadFromGoogleDrive = async (filename) => {
        const savedToken = localStorage.getItem('google_token');
        if (savedToken) {
            try {
                const response = await axios.get(`https://www.googleapis.com/drive/v3/files?q=name='${filename}' and trashed=false`, {
                    headers: {
                        'Authorization': 'Bearer ' + savedToken,
                    }
                });
    
                const files = response.data.files;
                if (files.length > 0) {
                    const fileId = files[0].id;
                    const fileResponse = await axios.get(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
                        headers: {
                            'Authorization': 'Bearer ' + savedToken,
                        }
                    });
    
                    return fileResponse.data;
                }
                return null;
            } catch (error) {
                console.error("Error loading file from Google Drive:", error);
                return null;
            }
        }
    };
    
    
    return { isSignedIn, signIn: login, signOut, uploadFile, saveToGoogleDrive, accessToken, loadFromGoogleDrive };
};

export default useGoogleApi;
