import React, { useState } from 'react';
import { List, ListItem, IconButton, Box, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery, Typography } from '@mui/material';
import { Check, Delete, FileCopy, Edit, CheckBox, CheckBoxOutlineBlank, Lock, LockOpen } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const MemoList = ({ memos, colors, changeColor, toggleTasklist, openEditDialog, toggleComplete, copyMemo, deleteMemo, toggleLock, openDialog, language }) => {
    const [selectedMemo, setSelectedMemo] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOpenDialog = (memo) => {
        setSelectedMemo(memo);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setSelectedMemo(null);
    };

    return (
        <>
            <List>
                {memos.map((memo, index) => (
                    <ListItem
                        key={index}
                        sx={{ backgroundColor: memo.status === '完了' ? '#d3d3d3' : memo.color || '#fafdf9', borderRadius: '8px', mb: 1 }}
                        onClick={() => handleOpenDialog(memo)}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', flexGrow: 1, minWidth: 0 }}>
                                <Typography variant="subtitle1" sx={{ textDecoration: memo.status === '完了' ? 'line-through' : 'none', fontWeight: 'bold', width: isMobile ? '100%' : '300px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {memo.title}
                                </Typography>
                                {!isMobile && (
                                    <Typography variant="body2" sx={{ textDecoration: memo.status === '完了' ? 'line-through' : 'none', ml: 2, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flexShrink: 0, width: 'calc(100% - 300px - 16px)' }}>
                                        {memo.content}
                                    </Typography>
                                )}
                            </Box>
                            {!isMobile && (
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Tooltip title={language === 'ja' ? 'タスクに追加' : 'Add to tasklist'}>
                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggleTasklist(index);
                                            }}
                                            color={memo.tasklist ? 'primary' : 'default'}
                                        >
                                            {memo.tasklist ? <CheckBox fontSize="small" /> : <CheckBoxOutlineBlank fontSize="small" />}
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={language === 'ja' ? '編集' : 'Edit'}>
                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                openEditDialog(index);
                                            }}
                                            color="primary"
                                        >
                                            <Edit fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={language === 'ja' ? '完了/未完了' : 'Complete/Incomplete'}>
                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggleComplete(index);
                                            }}
                                            color={memo.completed ? 'default' : 'primary'}
                                        >
                                            <Check fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={language === 'ja' ? 'コピー' : 'Copy'}>
                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                copyMemo(index);
                                            }}
                                            color="primary"
                                        >
                                            <FileCopy fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={language === 'ja' ? '削除' : 'Delete'}>
                                        <span>
                                            <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    deleteMemo(index);
                                                }}
                                                color="error"
                                                disabled={memo.locked}
                                            >
                                                <Delete fontSize="small" />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                    <Tooltip title={memo.locked ? (language === 'ja' ? 'ロック解除' : 'Unlock') : (language === 'ja' ? 'ロック' : 'Lock')}>
                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggleLock(index);
                                            }}
                                            color="default"
                                        >
                                            {memo.locked ? <Lock fontSize="small" /> : <LockOpen fontSize="small" />}
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            )}
                        </Box>
                    </ListItem>
                ))}
            </List>
            <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="sm">
                <DialogTitle>{selectedMemo?.title}</DialogTitle>
                <DialogContent>
                    <Typography sx={{ whiteSpace: 'pre-line' }}>{selectedMemo?.content}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        {language === 'ja' ? '閉じる' : 'Close'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MemoList;
