import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const PrivacyPolicy = ({ language }) => {
    return (
        <Container maxWidth="md" sx={{ padding: '16px' }}>
            <Box sx={{ textAlign: 'center', mb: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    {language === 'ja' ? 'プライバシーポリシー' : 'Privacy Policy'}
                </Typography>
            </Box>
            <Box>
                <Typography variant="body1" gutterBottom>
                    {language === 'ja'
                        ? '当アプリは、ユーザーがGoogle SSOを使用してログインする際に、以下のデータを収集します：'
                        : 'This app collects the following data when users log in using Google SSO:'}
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {language === 'ja'
                        ? '1. 収集するデータの種類:'
                        : '1. Types of data collected:'}
                    <ul>
                        <li>{language === 'ja' ? 'ユーザーの名前' : 'User name'}</li>
                        <li>{language === 'ja' ? 'メールアドレス' : 'Email address'}</li>
                        <li>{language === 'ja' ? 'プロフィール写真' : 'Profile picture'}</li>
                    </ul>
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {language === 'ja'
                        ? '2. データの使用目的:'
                        : '2. Purpose of data usage:'}
                    <ul>
                        <li>{language === 'ja' ? 'ユーザーの認証およびログイン' : 'User authentication and login'}</li>
                        <li>{language === 'ja' ? 'ユーザーのプロフィール情報をアプリ内で表示' : 'Displaying user profile information within the app'}</li>
                        <li>{language === 'ja' ? 'アプリのパーソナライズ' : 'Personalization of the app'}</li>
                    </ul>
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {language === 'ja'
                        ? '3. データの保存期間:'
                        : '3. Data retention period:'}
                    <ul>
                        <li>{language === 'ja' ? 'ユーザーデータは、ユーザーがアカウントを削除するまで保存されます。' : 'User data is retained until the user deletes their account.'}</li>
                    </ul>
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {language === 'ja'
                        ? '4. データの共有先:'
                        : '4. Data sharing:'}
                    <ul>
                        <li>{language === 'ja' ? '収集したデータは第三者と共有しません。' : 'Collected data is not shared with third parties.'}</li>
                    </ul>
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {language === 'ja'
                        ? '5. ユーザーの権利:'
                        : '5. User rights:'}
                    <ul>
                        <li>{language === 'ja' ? 'ユーザーは、自身のデータにアクセスし、修正し、削除する権利があります。これらの権利を行使するには、[コンタクト情報]にご連絡ください。' : 'Users have the right to access, correct, and delete their data. To exercise these rights, please contact [Contact Information].'}</li>
                    </ul>
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {language === 'ja'
                        ? '6. データ保護の方法:'
                        : '6. Data protection methods:'}
                    <ul>
                        <li>{language === 'ja' ? 'ユーザーデータは、暗号化およびアクセス制御によって保護されています。' : 'User data is protected through encryption and access controls.'}</li>
                    </ul>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {language === 'ja'
                        ? '当アプリは、ユーザーデータを安全に保護し、ユーザーのプライバシーを尊重します。また、当アプリのGoogle APIから受信した情報の使用および他のアプリへの転送は、Google APIサービスユーザーデータポリシー、特に限定使用要件に準拠します。詳細については、'
                        : 'This app ensures the security of user data and respects user privacy. Additionally, the use and transfer to any other app of information received from Google APIs will adhere to the Google API Services User Data Policy, including the Limited Use requirements. For more details, please refer to the '}
                    <a href="https://support.google.com/cloud/answer/9110914" target="_blank" rel="noopener noreferrer">
                        {language === 'ja' ? 'Google APIサービスユーザーデータポリシー' : 'Google API Services User Data Policy'}
                    </a>.
                </Typography>
            </Box>
        </Container>
    );
};

export default PrivacyPolicy;
