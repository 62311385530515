// ColorFilterButton.js
import React, { useState } from 'react';
import { Menu, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { FilterList } from '@mui/icons-material'; // フィルターアイコンのインポート

const ColorFilterButton = ({ colors, selectedColors, onChange, language }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectAll = () => {
        onChange(colors);
    };

    const handleDeselectAll = () => {
        onChange([]);
    };

    const handleColorToggle = (color) => {
        const currentIndex = selectedColors.indexOf(color);
        const newSelectedColors = [...selectedColors];

        if (currentIndex === -1) {
            newSelectedColors.push(color);
        } else {
            newSelectedColors.splice(currentIndex, 1);
        }

        onChange(newSelectedColors);
    };

    return (
        <div>
            <FilterList onClick={handleClick} style={{ cursor: 'pointer' , paddingRight:'16px' }} />
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleSelectAll}>
                    <Checkbox checked={selectedColors.length === colors.length} />
                    <ListItemText primary={language === 'ja' ? 'すべてチェックを付ける' : 'Select All'} />
                </MenuItem>
                <MenuItem onClick={handleDeselectAll}>
                    <Checkbox checked={selectedColors.length === 0} />
                    <ListItemText primary={language === 'ja' ? 'すべてチェックを外す' : 'Deselect All'} />
                </MenuItem>
                {colors.map((color) => (
                    <MenuItem key={color} onClick={() => handleColorToggle(color)}>
                        <Checkbox checked={selectedColors.indexOf(color) !== -1} />
                        <ListItemText primary={color} sx={{ backgroundColor: color, color: color,  width: '100%' ,border: '1px solid black' }}/>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default ColorFilterButton;
