import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    userSelect: 'none', // テキスト選択を防止
                },
            },
        },
    },
});

const ThemeProvider = ({ children }) => {
    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
