import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

function LandingPage() {
    return (
        <Container maxWidth="md" sx={{ padding: '16px' }}>
            <Box sx={{ textAlign: 'center', mb: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    メモカードアプリへようこそ
                </Typography>
            </Box>
            <Box sx={{ mb: 4 }}>
                <Typography variant="body1" gutterBottom>
                    このアプリは、ユーザーがGoogle SSOを使用してログインすることで、メモを作成、編集、および管理できるようにするものです。
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>ユーザーデータの使用方法:</strong> ログイン時に、Googleアカウントの基本情報（名前、メールアドレス、プロフィール写真）を収集します。これにより、アプリ内での認証とユーザー情報の表示が可能になります。
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>アプリの機能向上:</strong> このアプリは、簡単かつ効率的にメモを管理するための直感的なインターフェースを提供します。メモを作成、編集、削除、カラーコードで整理することができます。
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Link to="/privacy-policy">プライバシーポリシー</Link>
                </Typography>
            </Box>
        </Container>
    );
}

export default LandingPage;
