import React from 'react';
import { Snackbar, Alert } from '@mui/material';

export const SuccessSnackbar = ({ open, onClose, message }) => (
    <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
        <Alert onClose={onClose} severity="success" sx={{ width: '100%' }}>
            {message}
        </Alert>
    </Snackbar>
);

export const ErrorSnackbar = ({ open, onClose, message }) => (
    <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
        <Alert onClose={onClose} severity="error" sx={{ width: '100%' }}>
            {message}
        </Alert>
    </Snackbar>
);

export const NetworkErrorSnackbar = ({ open, onClose, message }) => (
    <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
        <Alert onClose={onClose} severity="error" sx={{ width: '100%' }}>
            {message}
        </Alert>
    </Snackbar>
);
