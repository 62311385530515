//App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { Box, Typography, Switch, IconButton, Tooltip, Button } from '@mui/material';
import { ViewKanbanOutlined, HomeOutlined } from '@mui/icons-material';
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useMediaQuery } from '@mui/material';
import '@fontsource/shadows-into-light'; // 英字のコンテンツラベル用のフォントをインポート
import '@fontsource/dela-gothic-one'; // 日本語のコンテンツラベル用のフォントをインポート
import { useNavigate } from 'react-router-dom'; // ホーム遷移のuseNavigateをインポート
//ファイルインポート
import MemoPage from './MemoPage';
import KanbanPage from './KanbanPage';
import PrivacyPolicy from './components/common/PrivacyPolicy.js';
import LandingPage from './components/common/LandingPage.js';
import useGoogleApi from './useGoogleApi';
import ThemeProvider from './components/common/ThemeProvider';


const CLIENT_ID = process.env.REACT_APP_GOOGLE_API_CLIENT_ID;
/*global google*/

function Header({ language, handleLanguageToggle, toggleStorage, handleSignIn, handleSignOut, isSignedIn }) {
  const location = useLocation();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate(); // useNavigateフックを使用

  return (
    <>
      <Analytics />
      <SpeedInsights />
      <Box sx={{ width: 'auto', backgroundColor: 'transparent', padding: '2px 16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant={isSmallScreen ? "h5" : "h4"}
            gutterBottom
            onClick={() => navigate('/')} // クリックでルートに遷移
            sx={{ cursor: 'pointer', fontFamily: 'Shadows Into Light, cursive', fontWeight: 'bold', userSelect: 'none' }} // 特殊なフォントを適用
          >
            memocard
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
          <Link to={location.pathname === '/kanban' ? '/' : '/kanban'} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black' }}>
            <Tooltip title={location.pathname === '/kanban' ? (language === 'ja' ? 'メモカードへ' : 'Go to memocard') : (language === 'ja' ? 'カンバンへ' : 'Go to Kanban')}>
              <IconButton color="primary">
                {location.pathname === '/kanban' ? <HomeOutlined /> : <ViewKanbanOutlined />}
              </IconButton>
            </Tooltip>
            <Typography variant="body1" component="span" sx={{ fontFamily: language === 'ja' ? 'Dela Gothic One' : 'Shadows Into Light', userSelect: 'none' , display: { xs: 'none', sm: 'block' } }}>
              {location.pathname === '/kanban' ? (language === 'ja' ? 'メモカード' : 'Memocard') : (language === 'ja' ? 'カンバン' : 'Kanban')}
            </Typography>
          </Link>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Typography variant="body1" component="span" sx={{ userSelect: 'none' ,display: { xs: 'none', sm: 'none' } }}>{language === 'ja' ? 'English' : '日本語'}</Typography>
            <Switch checked={language === 'en'} onChange={handleLanguageToggle} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', userSelect: 'none' }}>
            {isSignedIn ? (
              <Button onClick={handleSignOut}>Sign Out</Button>
            ) : (
              <div id="g_id_signin"></div>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

function GoogleOAuthContent({ language, handleLanguageToggle, toggleStorage, signIn, signOut, isSignedIn, saveToGoogleDrive, loadFromGoogleDrive }) {
  return (
    <>
      <Header
        language={language}
        handleLanguageToggle={handleLanguageToggle}
        toggleStorage={toggleStorage}
        handleSignIn={signIn}
        handleSignOut={signOut}
        isSignedIn={isSignedIn}
      />
      <Box sx={{ paddingTop: '12px' }}>
        <Routes>
          <Route path="/" element={<MemoPage language={language} saveToGoogleDrive={saveToGoogleDrive} loadFromGoogleDrive={loadFromGoogleDrive} />} />
          <Route path="/kanban" element={<KanbanPage language={language} saveToGoogleDrive={saveToGoogleDrive} loadFromGoogleDrive={loadFromGoogleDrive} />} />
          <Route path="/landing" element={<LandingPage language={language}/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy language={language}/>} />
        </Routes>
      </Box>
    </>
  );
}

function GoogleOAuthWrapper({ language, handleLanguageToggle, toggleStorage }) {
  const { signIn, signOut, isSignedIn, saveToGoogleDrive, loadFromGoogleDrive } = useGoogleApi();

  useEffect(() => {
    if (google && google.accounts && google.accounts.id) {
      google.accounts.id.initialize({
        client_id: CLIENT_ID,
        callback: signIn
      });
      google.accounts.id.renderButton(
        document.getElementById("g_id_signin"),
        { theme: "outline", size: "large" }
      );
    }
  }, [signIn, isSignedIn]);

  return (
    <GoogleOAuthContent
      language={language}
      handleLanguageToggle={handleLanguageToggle}
      toggleStorage={toggleStorage}
      signIn={signIn}
      signOut={signOut}
      isSignedIn={isSignedIn}
      saveToGoogleDrive={saveToGoogleDrive}
      loadFromGoogleDrive={loadFromGoogleDrive}
    />
  );
}

function App() {
  const [language, setLanguage] = useState('ja');

  const handleLanguageToggle = () => {
    setLanguage((prevLang) => (prevLang === 'ja' ? 'en' : 'ja'));
  };

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <ThemeProvider>
        <Router>
          <GoogleOAuthWrapper
            language={language}
            handleLanguageToggle={handleLanguageToggle}
          />
        </Router>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
